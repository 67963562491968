import React from 'react'
import bgImage from '../assets/AboutUs/bgImage.jpg'
import Tile from '../assets/AboutUs/Tile.png'
import bgTile from '../assets/AboutUs/bgTile.png'
import Team1 from '../assets/AboutUs/Team1.png'
import Team2 from '../assets/AboutUs/Team2.png'
import Team3 from '../assets/AboutUs/Team3.png'
import Team4 from '../assets/AboutUs/Team4.png'
import Team5 from '../assets/AboutUs/Team5.png'
import Team6 from '../assets/AboutUs/Team6.png'
import Team7 from '../assets/AboutUs/Team7.png'
import Team8 from '../assets/AboutUs/Team8.png'
import Team9 from '../assets/AboutUs/Team9.png'
import Team10 from '../assets/AboutUs/Team10.png'
import Team11 from '../assets/AboutUs/Team11.png'
import prin1 from '../assets/AboutUs/prin1.png'
import prin2 from '../assets/AboutUs/prin2.png'
import prin3 from '../assets/AboutUs/prin3.png'
import prin4 from '../assets/AboutUs/prin4.png'
import Adv from '../assets/AboutUs/Adv.png'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import LandingTile2 from '../components/LandingTile2'
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white min-h-screen scroll-smooth">

      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Alleshealth</title>
        <meta
          name="description"
          content="About Us - Alleshealth"
        />
        <meta
          name="keywords"
          content="Alles, alles, allesheath, Alleshealth, health, About Us - Alleshealth, About Alleshealth"
        />
      </Helmet>

      <LandingTile2
        bgImage={bgImage}
        headerText={'Providing better Health Care, for you and your loved ones.'}
        headerTop={20}
        mobileHeaderColor={'#3CA5DC'}
        headerColor={'#000000'}
        bottomColor={'#B1E9EF'} />

      {/* CONTENT */}
      <div className="flex flex-col justify-center items-center">
        {DATA.map((item) => (
          <div key={item.id} className="flex flex-col w-full mt-[8vh] justify-center items-center">
            {/* Header */}
            {item.header && <h1 className="font-bold text-4xl lg:text-[3.5vw] text-[#3CA5DC] mb-[8vh] text-center md:text-left">{item.header}</h1>}

            {/* Mission */}
            {item.sub && (
              <div key={item.sub.id} className="flex flex-col w-[80vw] mx-[10vw] justify-center items-start">
                {/* Subheader */}
                {/* Sub-items */}
                {item.sub.map((subItem) => (
                  <div className="flex flex-col justify-start items-start">
                    <ul >
                      {subItem.header && (
                        <li style={{ listStyleType: 'disc' }} className="font-semibold text-lg text-[#3CA5DC] mb-1 text-left md:text-left">
                          {subItem.header}
                        </li>
                      )}
                      <p className="font-regular text-md text-black mb-4 text-left md:text-left">{subItem.text}</p>
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {item.nodata && (
              <div className='relative'>
                {/* Background Image */}
                <div className="w-[90vw] mx-[5vw] h-auto flex justify-center items-center">
                  <img src={bgTile} alt="background" className="w-full h-[110vh] lg:h-[60vh] object-cover rounded-xl shadow-xl" /></div>
                <div className="absolute top-5">
                  <div className="mt-5 lg:mt-10 w-[70vw] mx-[15vw] flex flex-col lg:flex-row justify-center items-center gap-10">
                    <div className="w-full lg:w-1/2 flex flex-row justify-start items-center gap-10">
                      <div className="w-1/4 flex flex-col justify-center items-center gap-4">
                        <div className="w-[6vw]">
                          <img src={prin1} alt="principle1" className="w-full h-auto object-cover" />
                        </div>
                        <p className="font-semibold text-lg text-[#3CA5DC] mb-4 text-center">Full Trust</p>
                      </div>
                      <p className="w-3/4 font-regular text-md text-black mb-4 text-left md:text-left">We will always work in the Patient{"\'"}s interest to enable them to get the best Healthcare outcome.</p>
                    </div>

                    <div className="w-full lg:w-1/2 flex flex-row justify-start items-center gap-10">
                      <div className="w-1/4 flex flex-col justify-center items-center gap-4">
                        <div className="w-[6vw]">
                          <img src={prin2} alt="principle2" className="w-full h-auto object-cover" />
                        </div>
                        <p className="font-semibold text-lg text-[#3CA5DC] mb-4 text-center">Transparency</p>
                      </div>
                      <p className="w-3/4 font-regular text-md text-black mb-4 text-left md:text-left">We will always be transparent, so patients, clinics and carers have full visibility and control of their data set.</p>
                    </div>
                  </div>
                  <div className="mt-10 w-[70vw] mx-[15vw] flex flex-col lg:flex-row justify-start items-center gap-10">
                    <div className="w-full lg:w-1/2 flex flex-row justify-start items-center gap-10">
                      <div className="w-1/4 flex flex-col justify-center items-center gap-4">
                        <div className="w-[6vw]">
                          <img src={prin3} alt="principle3" className="w-full h-auto object-cover" />
                        </div>
                        <p className="font-semibold text-lg text-[#3CA5DC] mb-4 text-center">Patient-Centric</p>
                      </div>
                      <p className="w-3/4 font-regular text-md text-black mb-4 text-left md:text-left">We will always put Patient{"\'"}s interest first.</p>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-row justify-start items-center gap-10">
                      <div className="w-1/4 flex flex-col justify-center items-center gap-4">
                        <div className="w-[6vw]">
                          <img src={prin4} alt="principle4" className="w-full h-auto object-cover" />
                        </div>
                        <p className="font-semibold text-lg text-[#3CA5DC] mb-4 text-center">Security</p>
                      </div>
                      <p className="w-3/4 font-regular text-md text-black mb-4 text-left md:text-left">We will provide the security Patient{"\'"}s need to have complete data ownership.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Team */}
            {item.items && (
              <>
                {/* DESKTOP TEAM */}
                <div className="hidden md:flex flex-col w-full">
                  {item.items.map((item) => (
                    <div key={item.id} className={`flex ${item.id % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} h-[40vh] lg:h-[35vh] xl:h-[30vh]`}>
                      <div className={`bg-[#E2F5FE] relative flex w-[49%] gap-5 ${item.id % 2 === 0 ? 'flex-row-reverse rounded-r-full' : 'flex-row rounded-l-full'} items-center justify-end`}>
                        {/* Image */}
                        <div className={`flex absolute ${item.id % 2 === 0 ? 'right-[-6vw]' : 'left-[-6vw]'} w-2/5 h-full flex-row justify-center items-center`}>
                          <img src={item.image} alt={item.Name} className="w-auto h-full object-contain" />
                        </div>

                        {/* Text content */}
                        <div className="flex w-3/4 pl-[5vw] pr-5 py-5 flex-col justify-center items-start">
                          <h1 className="font-bold text-lg text-[#3CA5DC] mb-5 text-left">{item.Name}</h1>
                          <h1 className="font-bold text-lg text-black mb-1 text-left">{item.Designation}</h1>
                          <p className="text-lg font-regular text-black text-left">{item.Desc}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* MOBILE TEAM */}
                <div className="block md:hidden flex flex-col w-full gap-10">
                  {item.items.map((item) => (
                    <div key={item.id} className={`bg-[#E2F5FE] rounded-r-[30%] relative flex flex-col justify-center pl-[5vw] pr-5 py-5 items-start gap-10`}>
                      {/* Image */}
                      <div className={`flex w-[40%] h-auto flex-row justify-start items-center`}>
                        <img src={item.image} alt={item.Name} className="w-auto h-full object-contain" />
                      </div>

                      {/* Text content */}
                      <div className="flex w-3/4 flex-col justify-center items-start">
                        <h1 className="font-bold text-lg text-[#3CA5DC] mb-2 text-left">{item.Name}</h1>
                        <h1 className="font-semibold text-lg text-black mb-1 text-left">{item.Designation}</h1>
                        <p className="text-lg font-regular text-black text-left">{item.Desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* BLOG */}
      {/* <div onClick={{()=> {navigate("/blogPost")}}} */}
      {/* <div 
       style={{ background: 'rgba(190, 227, 246, 1)' }} className="mt-10 w-[70vw] mx-[15vw] flex flex-col lg:flex-row justify-center items-center px-10 rounded-2xl">
        <h1 className="xxxs:max-lg:mt-10 w-3/4 text-lg font-semibold text-left text-[#000000]">Want to Know more about our Work and have regular updates, <br />Here\'s our Blog Post for Insights and Keep Ups 🌻 </h1>
        <div className="lg:w-1/4 my-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 ">
          AllesHealth Blog
        </div>
      </div> */}

      {/* ADVISOR */}
      <div className="flex flex-col mt-10 lg:mt-[5vw] mb-[10vw] w-[80vw] mx-[10vw] justify-center items-center">
        {/* Header */}
        <h1 className="font-bold text-4xl lg:text-[3.5vw] text-[#3CA5DC] mb-[4vh] text-center md:text-left">Advisor(s)</h1>
        <div className="flex flex-col lg:flex-row w-[80vw] lg:w-1/2 justify-center items-center gap-10">
          <div className="flex flex-col lg:flex-row justify-center items-start lg:items-center gap-10">
            {/* Image */}
            <div className={`flex w-[50vw] lg:w-[35vw] flex-row justify-center items-center`}>
              <img src={Adv} alt="Advisor" className="w-full h-auto object-contain" />
            </div>

            {/* Text content */}
            <div className="flex lg:py-5 flex-col justify-center items-start">
              <h1 className="font-bold text-lg text-[#3CA5DC] mb-5 text-left">Life-Science Advisor</h1>
              <p className="text-lg font-regular text-black text-left">CEO of Hyperbolic Holdings || Investment holding, business development and strategy advisory|| .Multiple exits ||PhD in Mathematics from Harvard University.</p>
            </div>
          </div>
        </div>
      </div>

      {/* TILE 2 */}
      <div className="w-[90vw] lg:w-[70vw] mx-[5vw] lg:mx-[15vw] mb-[5vh]">
        <img src={Tile} alt="Tile" className="w-full h-auto object-cover" />
      </div>

      <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

      {/* FOOTER */}
      <Footer />
    </div >
  )
}

export default AboutUs;

const DATA = [
  {
    id: 0,
    header: 'Our Mission',
    sub: [
      {
        id: 0,
        text: 'To \'Empower Patients To Achieve Endless Possibilities In Healthcare\'',
      },
      {
        id: 1,
        text: 'Every time we heard a story of mis-diagnosis, late diagnosis, mis-treatment, it pained us to see how laggard our Healthcare industry has been so far in terms of Digitalization and lack of power to the patients.',
      },
      {
        id: 2,
        text: 'Every time we heard the story that patient data is shared across the spectrum, without any knowledge of the patient, it made us question the ethics.',
      },
      {
        id: 3,
        text: 'Every time we experienced the helplessness as a patient to choose what is best for our family, it made us question, is this the best we can do?',
      },
      {
        id: 4,
        text: 'AllesHealth Team Shares That Pain And Has An Urgent Desire To Provide Better Healthcare For Patients.',
      },
    ]
  },
  {
    id: 1,
    header: 'Guiding Principles',
    nodata: 'true',
  },
  {
    id: 2,
    header: 'Meet our Team',
    items: [
      {
        id: 0,
        image: Team1,
        Name: 'Sundeep Gaba',
        Designation: 'Founder',
        Desc: 'Senior Healthcare Leader of over 15 years || Business & Commercial Leadership, Patient Empowerment Advocate || MBA - University of Wales, Cardiff / EBHC/THS - University of Oxford',
      },
      {
        id: 1,
        image: Team2,
        Name: 'Amritpal Singh',
        Designation: 'Chief Technology Officer',
        Desc: 'Technology expertise in AI/ML & Blockchain || Over 10 years in Data and Network engineering || University of Toronto / Dalhousie University',
      },
      {
        id: 2,
        image: Team3,
        Name: 'Dulsara Kumarage',
        Designation: 'Director, Engineering',
        Desc: 'AI/ML Engineer with expertise in FE/BE development || Cardiff Met University, University of Plymouth',
      },
      {
        id: 3,
        image: Team4,
        Name: 'Dr. Abbas Khani',
        Designation: 'Clinical Researcher and Medical & Scientific Affairs',
        Desc: 'Expertise in Clinical Research || Medical and Scientific Affairs || PHD in Neuroscience, Fribourg || ETH, Zurich',
      },
      {
        id: 4,
        image: Team5,
        Name: 'Tarun Walia',
        Designation: 'Patient Representative and Director of Operations, India',
        Desc: 'Operations expert || Multi-year expertise in helping SMEs in Digital Transformation|| MBA - University of Wales, Cardiff',
      },
      {
        id: 5,
        image: Team7,
        Name: 'Aditya Kurniawan',
        Designation: 'User Experience Expert',
        Desc: 'UX and Design expert || Multi year experience in delivering patient centric solutions',
      },
      {
        id: 6,
        image: Team8,
        Name: 'Sayeed Afridi',
        Designation: 'Front End Developer',
        Desc: 'Expert in Front end Technologies || Development || HealthTech',
      },
      {
        id: 7,
        image: Team9,
        Name: 'Sakshi Raj',
        Designation: 'Product Developer',
        Desc: 'Expert in UI and UX Design || Software Quality Assurance & Tester ',
      },
      {
        id: 8,
        image: Team10,
        Name: 'Ayush Pandey',
        Designation: 'Full Stack Developer',
        Desc: 'Expert in Front end Technologies || Development || HealthTech',
      },
      {
        id: 9,
        image: Team11,
        Name: 'Biswajit Swain',
        Designation: 'Back End Developer',
        Desc: 'Expert in Back end Technologies || Development || HealthTech',
      },
    ],
  },
]