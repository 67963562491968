import React from 'react'
import LandingTile from '../components/LandingTile'
import bgImage from '../assets/ForPatientsCaregivers/bgImage.jpg'
import Circle1 from '../assets/ForPatientsCaregivers/Circle1.png'
import Circle2 from '../assets/ForPatientsCaregivers/Circle2.png'
import Circle3 from '../assets/ForPatientsCaregivers/Circle3.png'
import ProgramSunita from '../assets/OurProducts/ProgramSunita.png'
import bgFlowers from '../assets/OurProducts/bgFlowers.png'
import { MdOutlineArrowOutward } from "react-icons/md";
import ForPatients from '../assets/ForPatientsCaregivers/ForPatients.png'
import ForCarers from '../assets/ForPatientsCaregivers/ForCarers.png'
import What from '../assets/ForPatientsCaregivers/What.png'
import Icon1 from '../assets/ForPatientsCaregivers/Icon1.png'
import Icon2 from '../assets/ForPatientsCaregivers/Icon2.png'
import Icon3 from '../assets/ForPatientsCaregivers/Icon3.png'
import Icon4 from '../assets/ForPatientsCaregivers/Icon4.png'
import Icon5 from '../assets/ForPatientsCaregivers/Icon5.png'
import Icon6 from '../assets/ForPatientsCaregivers/Icon6.png'
import Icon7 from '../assets/ForPatientsCaregivers/Icon7.png'
import Icon8 from '../assets/ForPatientsCaregivers/Icon8.png'
import Icon9 from '../assets/ForPatientsCaregivers/Icon9.png'
import Icon10 from '../assets/ForPatientsCaregivers/Icon10.png'
import Icon11 from '../assets/ForPatientsCaregivers/Icon11.png'
import Icon12 from '../assets/ForPatientsCaregivers/Icon12.png'
import Icon13 from '../assets/ForPatientsCaregivers/Icon13.png'
import Icon14 from '../assets/ForPatientsCaregivers/Icon14.png'
import Tile from '../assets/ForPatientsCaregivers/Tile.png'
import Tile2 from '../assets/ForPatientsCaregivers/Tile2New.png'
import Footer from '../components/Footer'
import TextComponent from '../components/TextComponent'
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

const ForPatientsCaregivers = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white min-h-screen scroll-smooth">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Alleshealth - For Patients Caregivers</title>
        <meta
          name="description"
          content="At AllesHealth, we believe Health Data is the Soil through which many new trees can be planted and the fruits of those should be shared among all, especially Patients and Carers, as the rightful owners of Health Data"
        />
        <meta
          name="keywords"
          content="Alles, alles, allesheath, Alleshealth, health"
        />
      </Helmet>

      <LandingTile
        bgImage={bgImage}
        headerText={'For the best care of your loved ones'}
        headerColor={'#000000'}
        subparagraphText={'At AllesHealth, we believe Health Data is the \'Soil\' through which many new trees can be planted and the fruits of those should be shared among all, especially Patients and Carers, as the rightful owners of Health Data'}
        subColor={'#FFFFFF'}
        bottomColor={'#B6E6FD'} />

      <div className="flex flex-col-reverse lg:flex-row justify-center align-center w-[80vw] h-auto mx-[10vw] my-[2vh] lg:my-[10vh] gap-10">
        {/* TEXT on left */}
        <div className="flex flex-col justify-center align-center w-full lg:w-1/2">
          <TextComponent DATA={DATA} />
        </div>

        {/* IMAGES on right */}
        <div className="w-full lg:w-1/2 pl-13 h-auto flex flex-col justify-center align-center items-center content-center relative  lg:gap-[20vh]">
          <div className="homeBg w-full">
            <img src={Circle1} alt="Image1" className="w-3/5 lg:w-[24vw] lg:h-[24.5vw] object-contain rounded-full shadow-xl" />
          </div>
          <div className="homeBg w-full flex justify-end absolute right-[-3vw] xxxs:max-lg:top-[32vw]">
            <img src={Circle2} alt="Image2" className="w-1/2 lg:w-[25vw] lg:h-[23.5vw] object-contain rounded-full shadow-xl" />
          </div>
          <div className="homeBg mt-[7vh] lg:mt-[18vh] w-full">
            <img src={Circle3} alt="Image3" className="w-1/2 lg:w-[24vw] lg:h-[25vw] object-contain rounded-full shadow-xl" />
          </div>
        </div>
      </div>

      {/* Tile */}
      <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="relative mt-10 w-[93vw] h-auto ml-[5vw]">
        <img src={Tile} alt="Tile" className="w-[90vw] mx-[5vw] lg:w-[93vw] h-auto object-cover cursor-pointer" />
        <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="absolute bottom-0 left-[-45vw] w-1/4 h-1/2"></a>
      </a>

      {/* Tile 2 */}
      <div className="relative mt-10 mb-[10vh] w-[86vw] lg:w-[90vw] h-full mx-[5vw] cursor-pointer">
        <img src={Tile2} alt="Tile" className="w-full h-auto object-cover" />
        <a href='https://partner.alleshealth.com/' target='_blank' className="absolute top-0 left-0 w-1/2 h-[19vw]"></a>
        <div onClick={() => { navigate("/sunita") }} className="absolute bottom-0 right-0 w-1/2 h-full"></div>
        <div onClick={() => { navigate("/contactus") }} className="absolute bottom-0 left-0 w-1/2 h-[27%]"></div>
      </div>

      {/* TILE 2 */}
      {/* <div style={{ overflow: 'hidden' }} className="relative bg-[#FDEDD4] w-[90vw] h-[25vw] mx-[5vw] my-[5vh] flex flex-row justify-center items-center rounded-2xl shadow-xl cursor-pointer">
        <div onClick={() => { navigate("/sunita") }} className="absolute bottom-[-5vh] md:bottom-[-10vh] left-[-7vw] z-0 opacity-50">
          <img src={bgFlowers} alt="bgFlowers" className="w-[25vw] h-auto object-contain" />
        </div>
        <div onClick={() => { navigate("/sunita") }} className="absolute bottom-[-5vh] md:bottom-[-10vh] right-[-7vw] z-0 opacity-50">
          <img src={bgFlowers} alt="bgFlowers" className="w-[25vw] h-auto object-contain" />
        </div>

        <div onClick={() => { navigate("/sunita") }} className="flex basis-1/4 flex-col justify-end h-auto items-center gap-5 z-10 cursor-pointer">
        </div>

        <div onClick={() => { navigate("/sunita") }} className="basis-1/2 flex justify-center items-center z-10">
          <img src={ProgramSunita} alt="ProgramSunita" className="w-full h-auto object-contain" />
        </div>

        <a href='https://partner.alleshealth.com/' target='_blank' className="flex basis-1/4 flex-col justify-end h-auto items-center gap-5 z-10 cursor-pointer">
          <div className="bg-[#942944] flex justify-center font-bold text-[#FFFFFF] text-[1vw] items-center w-3/4 px-1 lg:px-2 py-1 md:py-4 rounded-l-full rounded-r-full shadow-xl gap-1 lg:gap-2">
            Become a Partner <MdOutlineArrowOutward />
          </div>
          <div onClick={() => { navigate("/contactus") }} className="bg-[#D45573] flex justify-center font-bold text-[#FFFFFF] text-[1vw] items-center w-3/4 px-1 lg:px-2 py-1 md:py-4 rounded-l-full rounded-r-full shadow-xl gap-1 lg:gap-2">
            Donate for the Effort <MdOutlineArrowOutward />
          </div>
        </a>
      </div> */}

      {/* <div className="basis-1/4 h-[20vw] flex flex-col justify-end items-start z-10 gap-5">
                    <div className="flex flex-row justify-end items-end gap-5">
                        <img src={linkedinIcon} alt="Linkedin Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                        <img src={loopIcon} alt="Loop Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                    </div>
                    <div className="flex flex-row justify-end items-end gap-5">
                        <img src={instaIcon} alt="Insta Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                        <img src={telegramIcon} alt="Telegram Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                    </div>
                </div> */}

      <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

      {/* FOOTER */}
      <Footer />
    </div >
  )
}

export default ForPatientsCaregivers;

const DATA = [
  {
    id: 1,
    Icon: ForPatients,
    header: 'For Patients',
    sub: 'AllesHealth Provides a Platform For Patients That Helps Them Proactively Manage Their Health and be Independent.',
    data: [
      {
        id: 1,
        icon: Icon1,
        text: 'Track your Health Journey and see progress.',
      },
      {
        id: 2,
        icon: Icon2,
        text: 'Maintain your Health Records all in one place. ',
      },
      {
        id: 3,
        icon: Icon3,
        text: 'Get Dosage reminders and mark calendars.',
      },
      {
        id: 4,
        icon: Icon4,
        text: 'Personalize your account data.',
      },
    ],
  },
  {
    id: 2,
    Icon: ForCarers,
    header: 'For Carers',
    sub: 'AllesHealth Provides a Platform For Carers by providing Insights of patients well being To Understand Disease Through Their Own Health Data.',
    data: [
      {
        id: 1,
        icon: Icon5,
        text: 'Get daily health tips and facts.',
      },
      {
        id: 2,
        icon: Icon6,
        text: 'Maintain a family tree and become super carer.',
      },
      {
        id: 3,
        icon: Icon7,
        text: 'Information about nearby Labs, Clinics and Hospitals.',
      },
      {
        id: 4,
        icon: Icon8,
        text: 'Give feedback and manage data consent.',
      },
    ],
  },
  {
    id: 3,
    Icon: What,
    header: 'What is in it for you',
    sub: 'We Want To Provide The Control Of Health Data To The Real Owner \'PATIENTS\' And Ensure Patients Are Rewarded For Healthy Behavior.',
    data: [
      {
        id: 1,
        icon: Icon9,
        text: 'Complete Transparency about your data usage.',
      },
      {
        id: 2,
        icon: Icon10,
        text: 'Get best health tips & recommendation',
      },
      {
        id: 3,
        icon: Icon11,
        text: 'Help in Personalized treatments.',
      },
      {
        id: 4,
        icon: Icon12,
        text: 'In hand availability of digital health records.',
      },
      {
        id: 5,
        icon: Icon13,
        text: 'Easy and simple to use interface and features',
      },
      {
        id: 6,
        icon: Icon14,
        text: 'Clinical health insights with Alles Health',
      },
    ],
  },
]