import React, { useEffect } from 'react'
import HomeBg from '../assets/PartnerPortal/bgImage-min.png'
import Image1 from '../assets/PartnerPortal/Image1.png'
import Image2 from '../assets/PartnerPortal/Image2.png'
import Image3 from '../assets/PartnerPortal/Image3.png'
import Image4 from '../assets/PartnerPortal/Image4.png'
import Tile from '../assets/PartnerPortal/Tile.png'
import AppStore from '../assets/PartnerPortal/AppStore.png';
import PlayStore from '../assets/PartnerPortal/PlayStore.png';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MdOutlineArrowOutward } from "react-icons/md";
import { Helmet } from "react-helmet";

const PartnerPortal = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-white min-h-screen scroll-smooth">

            <Helmet>
                <meta charSet="utf-8" />
                <title>AllesHealth Partner Portal</title>

                <meta
                    name="description"
                    content="Provide services directly to the patients without any high referral fees, bring your business online, and earn more."
                />
                <meta
                    name="keywords"
                    content="digital healthcare,
                    private health care,
                    home health care,
                    home health,
                    private medical care,
                    company healthcare,
                    healthcare provider near me,
                    my health care,
                    patient care services,
                    care management,
                    healthcare website,
                    health care center,
                    health care website,
                    health care at home,
                    best healthcare,
                    medical provider,
                    private healthcare providers,
                    health care center near me,
                    health care provider near me,
                    best health care,
                    health clinics,
                    medical health care,
                    healthcare for all,
                    health care policy,
                    low cost healthcare, low cost health care,
                    health partner,
                    healthcare cost,
                    low cost medical insurance near me,
                    healthcare policies,
                    medical care at home,
                    health providers,
                    healthcare company,
                    medical healthcare,
                    at home health care,
                    better healthcare,
                    at home health care services,
                    at home medical care,
                    family health services,
                    healthcare database,
                    low cost health insurance near me,
                    health care app,
                    patient care at home,
                    health services near me,
                    cheap health care near me,
                    in home patient care,
                    benefits of private healthcare,
                    health care it,
                    public healthcare,
                    in home medical care near me, health care professionals,
                    family health care center,
                    health care medical,
                    low cost health centers near me,
                    patient care services at home,
                    medical care center,
                    healthcare clinics,
                    low cost medical care near me,
                    cheap medical care near me,
                    private health services,
                    at home home health,
                    digital health company,
                    health care services near me,
                    medical health services,
                    private health care services,
                    best medical website,
                    health medical,
                    health and care,
                    complete health care,
                    health care systems,
                    partners health,
                    in home medical services,
                    low cost health care near me,
                    private healthcare services,
                    we health,
                    healthcare it company,
                    health care now, private medical services,
                    at home patient care,
                    home medical visits,
                    home and health care,
                    home health care at home,
                    medical facilities,
                    best medical care,
                    cost of health,
                    low cost medical care,
                    home patient care,
                    digital health app,
                    private health care provider,
                    future of health,
                    health care medical center,
                    health care home"
                />
            </Helmet>

            {/* DESKTOP LANDING TILE */}
            <div className="hidden md:flex w-full h-50vh bg-cover bg-center flex justify-center items-center relative">
                {/* BG Image */}
                <div style={{ overflow: 'hidden' }} className="homeBg w-full h-[100vh]">
                    <LazyLoadImage
                        src={HomeBg}
                        effect="blur"
                        width={'100vw'}
                        // height={'90vh'}
                        placeholderSrc={HomeBg}
                        alt="Background Image"
                        className="w-full h-[100vh] object-cover" />
                </div>

                {/* Image Left Text */}
                <div className="p-6 rounded-md absolute w-[80vw] mx-[10vw] text-center top-12 lg:top-12 xl:top-[20vh] flex flex-col justify-center items-center">
                    <h1 className="text-[4.4vw] font-bold mb-4 text-[#FFFFFF]">AllesHealth Partner Portal</h1>
                    <h1 className="text-[2.4vw] font-semibold mb-4 text-[#FFFFFF]">Becoming the bridge between Patients and Healthcare providers for everyone's digital needs.</h1>
                    <a href='https://partner.alleshealth.com/' target='_blank' className="my-5 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[25vw] lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Become a Partner <MdOutlineArrowOutward />
                    </a>
                    <h1 className="mt-10 text-lg font-semibold mb-4 text-[#FFFFFF]">DOWNLOAD ALLESHEALTH APP</h1>

                    <div className="flex flex-row justify-center items-center gap-10">
                        <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="cursor-pointer w-[10vw]">
                            <img src={AppStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="cursor-pointer w-[10vw]">
                            <img src={PlayStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                    </div>
                </div>
            </div>

            {/* MOBILE LANDING TILE */}
            <div className="block md:hidden w-full h-40vh bg-cover bg-center flex flex-col justify-center items-center">
                {/* BG Image */}
                <div className="homeBg w-full">
                    <img src={HomeBg} alt="Home background" className="w-full h-auto object-cover" />
                </div>

                {/* Image Text */}
                <div className="px-[10vw] w-full text-left flex flex-col justify-center items-center">
                    <h1 className="text-4xl text-center leading-[6vh] font-bold mb-4 text-[#3CA5DC] mt-5">AllesHealth Partner Portal</h1>
                    <h1 className="text-4xl text-center font-regular mb-4 text-[#000000] mt-5">Becoming the bridge between Patients and Healthcare providers for everyone's digital needs.</h1>

                    <a href='https://partner.alleshealth.com/' target='_blank' className="my-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[60vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Become a Partner <MdOutlineArrowOutward />
                    </a>

                    <h1 className="text-2xl text-center font-regular mb-4 text-[#000000] mt-5">
                        DOWNLOAD ALLESHEALTH APP
                    </h1>
                    <div className="mb-10 flex flex-row justify-center items-center gap-10">
                        <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="cursor-pointer w-[30vw]">
                            <img src={AppStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="cursor-pointer w-[30vw]">
                            <img src={PlayStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="w-full flex flex-col justify-center items-center">
                <h1 className="text-6xl font-bold mb-4 text-[#42B8F7] mt-5">Partner Portal</h1>

                {/* Partner Portal 1 */}
                <div className="w-[80vw] mx-[10vw] mt-10 flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                    <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
                        <img src={Image1} alt="Portal" className="w-full h-auto object-cover" />
                    </div>
                    <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
                        <ul className='text-left'>
                            <li style={{ listStyle: 'disc' }}>Sign Up to Partner Portal and select a plan that suits your needs the best.</li>
                            <li style={{ listStyle: 'disc' }}>Create Offers that you want to provide as a service to your Customers.</li>
                            <li style={{ listStyle: 'disc' }}>Send the offer to get reviewed and verified by AllesHealth Team.</li>
                            <li style={{ listStyle: 'disc' }}>Sit back and wait, usually takes 24 - 48 hours for offer verification.</li>
                        </ul>
                        <a href='https://partner.alleshealth.com/' target='_blank' className="mt-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-full md:w-1/2 lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                            Become a Partner <MdOutlineArrowOutward />
                        </a>
                    </div>
                </div>

                {/* App */}
                <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.12)' }} className="w-full my-[8vh]">
                    <div className="w-[80vw] mx-[10vw] py-[5vh] lg:py-[2vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
                            <img src={Image2} alt="App" className="w-[25vh] h-auto object-cover" />
                        </div>
                        <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
                            <ul className='text-left'>
                                <li style={{ listStyle: 'disc' }}>Before pushing your offers through, select all the parameters correctly.</li>
                                <li style={{ listStyle: 'disc' }}>Discount percentage, Name of your product, Correct description along with Location etc.</li>
                                <li style={{ listStyle: 'disc' }}>Upload the Product Image to be displayed to the customers and type of offer i.e. Offline or Online.</li>
                            </ul>
                            <a href='https://partner.alleshealth.com/' target='_blank' className="mt-10 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full md:w-1/2 lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                Login/Sign Up <MdOutlineArrowOutward />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Tile */}
                <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="w-[80vw] mx-[10vw] relative">
                    <img src={Tile} alt="Tile" className="w-full h-auto object-cover" />
                    <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="absolute bottom-0 left-0 w-[15vw] h-1/2"></a>
                </a>


                {/* Partner Portal 2 */}
                <div className="w-[80vw] mx-[10vw] mt-[8vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                    <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
                        <img src={Image3} alt="Portal" className="w-full h-auto object-cover" />
                    </div>
                    <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
                        <ul className='text-left'>
                            <li style={{ listStyle: 'disc' }}>Once your Offer gets Accepted, go ahead and start monitoring the sales and compliance.</li>
                            <li style={{ listStyle: 'disc' }}>You can also add new employees to your existing organizational account, maintain the access controls and their privileges.</li>
                            <li style={{ listStyle: 'disc' }}>Along with this you also get to add upto 6 Branches of your office/lab/clinic in the main account (Branch addition varies on the plan).</li>
                        </ul>
                        <a href='https://partner.alleshealth.com/' target='_blank' className="mt-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-full md:w-1/2 lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                            Become a Partner <MdOutlineArrowOutward />
                        </a>
                    </div>
                </div>

                {/* App 2 */}
                <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.12)' }} className="w-full mt-[8vh] mb-[10vh]">
                    <div className="w-[80vw] mx-[10vw] py-[5vh] lg:py-[2vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
                            <img src={Image4} alt="App" className="w-[25vh] h-auto object-cover" />
                        </div>
                        <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
                            <ul className='text-left'>
                                <li style={{ listStyle: 'disc' }}>Listed Offerings would look like this to the end users and customers, In the Health rewards section.</li>
                                <li style={{ listStyle: 'disc' }}>Users can redeem coins and use your services.</li>
                                <li style={{ listStyle: 'disc' }}>AllesHealth Partner Portal can be used by Laboratories, Hospitals and Merchandise stores.</li>
                            </ul>
                            <a href='https://partner.alleshealth.com/' target='_blank' className="mt-10 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full md:w-1/2 lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                Login/Sign Up <MdOutlineArrowOutward />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    )
}

export default PartnerPortal