import React from 'react'
import LandingTile from '../components/LandingTile'
import bgImage from '../assets/ForHealthInsurarers/bgImage.png'
import Tile from '../assets/ForHealthInsurarers/Tile.png'
import Circle1 from '../assets/ForHealthInsurarers/Circle1.png'
import Circle2 from '../assets/ForHealthInsurarers/Circle2.png'
import Circle3 from '../assets/ForHealthInsurarers/Circle3.png'
import ForHealthInsurance from '../assets/ForHealthInsurarers/ForHealthInsurance.png'
import What from '../assets/ForHealthInsurarers/What.png'
import Icon1 from '../assets/ForHealthInsurarers/Icon1.png'
import Icon2 from '../assets/ForHealthInsurarers/Icon2.png'
import Icon3 from '../assets/ForHealthInsurarers/Icon3.png'
import Icon4 from '../assets/ForHealthInsurarers/Icon4.png'
import Icon5 from '../assets/ForHealthInsurarers/Icon5.png'
import Icon6 from '../assets/ForHealthInsurarers/Icon6.png'
import Icon7 from '../assets/ForHealthInsurarers/Icon7.png'
import Icon8 from '../assets/ForHealthInsurarers/Icon8.png'
import Icon9 from '../assets/ForHealthInsurarers/Icon9.png'
import Icon10 from '../assets/ForHealthInsurarers/Icon10.png'
import Footer from '../components/Footer'
import TextComponent from '../components/TextComponent'
import { Helmet } from "react-helmet";

const ForHealthInsurarers = () => {
  return (
    <div className="bg-white min-h-screen scroll-smooth">

<Helmet>
        <meta charSet="utf-8" />
        <title>AllesHealth - For Health Insurarers</title>
        <meta
          name="description"
          content="Alles Health is one stop solution for both Users and Businesses to reach their maximum potential in healthcare and provide best health care to their patients and loved ones."
        />
        <meta
          name="keywords"
          content="Alles, alles, allesheath, Alleshealth, health, For Health Insurarers"
        />
      </Helmet>

      <LandingTile
        bgImage={bgImage}
        headerText={'Why you should choose us?'}
        headerColor={'#000000'}
        subparagraphText={'Alles Health is one stop solution for both Users and Businesses to reach their maximum potential in healthcare and provide best health care to their patients and loved ones.'}
        subColor={'#FFFFFF'}
        bottomColor={'#FCDDB1'} />

      <div className="flex mx-[10vw] justify-center items-center mt-10">
        <h1 className="text-4xl font-bold text-[#42B8F7] my-2 text-center md:px-5">We Empower Patients To Achieve Endless Possibilities In Healthcare</h1>
      </div>

      <div className="flex flex-col-reverse lg:flex-row justify-center align-center w-[80vw] h-auto mx-[10vw] my-[2vh] lg:mt-[10vh] gap-10 relative">
        {/* TEXT on left */}
        <div className="flex flex-col justify-center align-center w-full lg:w-1/2">
          <TextComponent DATA={DATA} />
        </div>

        {/* IMAGES on right */}
        <div className="w-full lg:w-1/2 pl-13 h-auto flex flex-col justify-center align-center items-center content-center relative  lg:gap-[0vh]">
          <div className="homeBg w-full flex justify-end">
            <img src={Circle1} alt="Image1" className="w-3/5 lg:w-[22vw] lg:h-[22vw] object-contain rounded-full" />
          </div>
          <div className="homeBg w-full absolute right-[-3vw] xxxs:max-lg:top-[32vw]">
            <img src={Circle2} alt="Image2" className="w-1/2 lg:w-[15vw] lg:h-[15vw] object-contain rounded-full" />
          </div>
          <div className="homeBg mt-[7vh] lg:mt-[18vh] w-full flex justify-end">
            <img src={Circle3} alt="Image3" className="w-1/2 lg:w-[22vw] lg:h-[22vw] object-contain rounded-full" />
          </div>
        </div>
      </div>


      {/* TILE */}
      <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="relative Tile w-[80vw] lg:w-1/2 mb-10 mx-[10vw] flex justify-center items-start cursor-pointer">
        <img src={Tile} alt="Tile" className="w-full h-auto object-contain" />
        <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="absolute bottom-0 right-0 w-[21.5%] h-1/2"></a>
      </a>

      <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

      {/* FOOTER */}
      <Footer />
    </div>
  )
}

export default ForHealthInsurarers;

const DATA = [
  {
    id: 1,
    Icon: ForHealthInsurance,
    header: 'For Health Insurance Providers',
    sub: 'AllesHealth Is A True Partner For Health Insurance Providers. By Providing Customisable, White-Label Platform App, AllesHealth Helps Companies Achieve Higher Customer Satisfaction And Top-Line Growth.',
    data: [
      {
        id: 1,
        icon: Icon1,
        text: 'Patients Data Is Completely Anonymised For Privacy',
      },
      {
        id: 2,
        icon: Icon2,
        text: 'Top-Line Growth Through Family Care Offerings',
      },
      {
        id: 3,
        icon: Icon3,
        text: 'AI Generated Actionable Insights For Patients & Carers.',
      },
      {
        id: 4,
        icon: Icon4,
        text: 'Storage Of Health Records From Multiple Sources',
      },
    ],
  },
  {
    id: 2,
    Icon: What,
    header: 'What is in it for you',
    sub: 'AllesHealth Is A Perfect Solution As A Digital Companion For Your Patients That Can Help Increase Efficiency Of Your Clinical Staff And Also Improve The Way You Engage With Your Patients.',
    data: [
      {
        id: 1,
        icon: Icon5,
        text: 'Reach out to more than 10 Lacs Customers',
      },
      {
        id: 2,
        icon: Icon6,
        text: 'Decrease your Customer acquisition cost',
      },
      {
        id: 3,
        icon: Icon7,
        text: 'Improve your Company/Brand Visibility',
      },
      {
        id: 4,
        icon: Icon8,
        text: 'Save Money on Marketing Budgets',
      },
      {
        id: 5,
        icon: Icon9,
        text: 'Get more Customer Walk-Ins to your Physical Office',
      },
      {
        id: 6,
        icon: Icon10,
        text: 'Experience Increase in Sales',
      },
    ],
  },
]